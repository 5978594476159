.ToolCards .card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 288px;
    align-content: flex-end;
}
.ToolCards .card img{
    width: 65%;
}
.hi{
    border-radius: 12px;
  overflow: hidden;
}
#TechnicalAnalys .card .row .widgets div div div {
    border-radius: 12px;
    overflow: hidden;
}