.card .withdraw_form{
	height: 70vh;
}
.el-card {
	color: #303133;
}
.el-card .el-card__body {
	padding: 40px;
	border: none;
}
.form-wrapper {
	margin: 2rem auto;
	max-width: 840px;
}
.withdraw_form .btn-wrapper {
	margin-top: 36px;
	text-align: center;
}
.withdraw_form .btn-wrapper button{
	padding: 15px 40px;
	border-radius: 10px;
}
