.channel {
  min-height: 232px;
  background-color: #fff;
  box-shadow: 0px 0px 25px -5px #0000002f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#DepositFunds .deposit_channel,
#WithdrawFunds .deposit_channel {
  box-shadow: 0px 0px 25px -5px #0000002f;
  border-radius: 10px;
  padding: 10px;
}
#DepositFunds .active-channel,
#WithdrawFunds .active-channel {
  box-shadow: 0px 0px 10px 1px #0000002f;
  padding: 9px;
  border: 1px solid #00a51b;
}
.logo_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logo img {
  height: 40px;
  aspect-ratio: 1/1;
}
.title {
  font-size: 0.7rem;
  color: #626364;
  font-weight: 500;
  text-align: start;
}
.card_bottom {
  min-height: 50px;
  padding-top: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.card_bottom .card_bottom_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card_bottom .card_bottom_item span {
  color: #6e6e73;
  font-size: 14px;
  padding-right: 5px;
  line-height: 20px;
}
.SelectBox {
  padding: 5px 15px;
  background-color: #c9c9c979;
  border-radius: 30px;
}
.labelContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
#PaymentDeposit .card {
  padding: 20px;
}
#PaymentDeposit .form-custom {
  display: block;
  width: 100%;
  padding: 08px 12px;
  line-height: 20px;
  color: #fdd901;
}
#PaymentDeposit .form-custom::file-selector-button {
  padding: 8px 16px;
  margin-inline-end: 16px;
  color: #fff;
  background-color: #000000;
  pointer-events: none;
  border: none;
  border-radius: 0px 5px 5px 0px;
}
#DepositFunds .input-group-append span {
  border-radius: 0 10px 10px 0;
}

#DepositFunds .deposite-window,
#WithdrawFunds .deposite-window {
  margin-top: 2rem;
}

@media (min-width: 999px) {
  #DepositFunds .deposite-window,
  #WithdrawFunds .deposite-window {
    max-width: 920px;
    margin: auto;
    margin-top: 2rem;
  }
}
@media (max-width: 767px) {
  .DepositType,
  .WithdrawType {
    overflow-x: scroll;
    padding: 0 10px;
  }
  .DepositType .deposit_channel,
  .WithdrawType .deposit_channel {
    min-width: 204px;
    margin: 10px 0;
  }
}
