.reward-points,
#WheelSpin .card,
#LuckyDraw .card,
#Vouchers .card{
    margin: auto;
    max-width: 1200px;
}
.reward-points .card-body img{
    width: 28px;
}
#Rewards .reward-points .d-flex .text-center img{
    height: 80px;
    aspect-ratio: 1/1;
    margin: 10px auto 20px;
}
#LuckyDraw .card .Cash-icon{
    width: 90px;
    height: 90px;
    margin-right: 10px;
}
#Vouchers .Vouchers {
    padding: 25px;
    background: #1c1c1c;
    color: #fff;
    --mask:
        linear-gradient(#000 0 0) 50%/calc(100% - 12px) calc(100% - 12px) no-repeat,
        radial-gradient(6px, #0000 98%, #000) 0 -6px/11.1px 12px round no-repeat,
        radial-gradient(6px, #0000 98%, #000) 0 calc(100% + 6px)/11.1px 12px round no-repeat,
        radial-gradient(6px, #0000 98%, #000) -6px 0/12px 11.1px no-repeat round,
        radial-gradient(6px, #0000 98%, #000) calc(100% + 6px) 0/12px 11.1px no-repeat round;
    clip-path: polygon(12px 0, calc(100% - 12px) 0, 100% 12px, 100% calc(100% - 12px), calc(100% - 12px) 100%, 12px 100%, 0 calc(100% - 12px), 0 12px);
    -webkit-mask: var(--mask);
    mask: var(--mask);
}
#Vouchers .Vouchers img{
    min-width: 60px;
    min-height: 60px;
    max-width: 61px;
    max-height: 61;
    border-radius: 50%;
    border: 1px solid white;
}