* {
  font-family: CenturyGothic, Arial, "Microsoft YaHei", sans-serif;
  font-size: 15px;
}

body {
  font-family: Arial, sans-serif;
}

.sidebar{
  background-color: #07548d;
}


.sidebar .nav-link {
    overflow-wrap: break-word; /* Ensures that long words break and wrap to the next line */
    word-wrap: break-word;     /* Legacy support for older browsers */
  }
  
  .sidebar .menu-title {
    display: block;
    overflow-wrap: break-word; /* Allows wrapping of long words */
    word-wrap: break-word;     /* Legacy support for older browsers */
    white-space: normal;       /* Allows text to wrap */
  }
  
  .sidebar .nav-item {
    /* Ensure that nav-items allow for text wrapping */
    margin-bottom: 0.5rem; /* Add some spacing between items if needed */
  }
  
  .custom-table {
    border: none; /* Removes the borders from the table */
  }
  
  .custom-table th,
  .custom-table td {
    border: none; /* Removes the borders from table cells */
  }
  
  .custom-table thead th {
    background-color: #f8f9fa; /* Light background color for header */
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f1f1; /* Hover effect for rows */
  }
  
  .custom-table img {
    max-width: 100px; /* Adjust the size of receipt images */
  }
  

  .custom-table {
    border: none; /* Removes the borders from the table */
    margin-bottom: 20px; /* Adds space below the table */
  }
  
  .custom-table th,
  .custom-table td {
    border: none; /* Removes the borders from table cells */
  }
  
  .custom-table thead th {
    background-color: #f8f9fa; /* Light background color for header */
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f1f1; /* Hover effect for rows */
  }
  
  .custom-table img {
    max-width: 100px; /* Adjust the size of receipt images */
  }
  
  /* Optional: Add space below the card body if needed */
  .card-body {
    padding-bottom: 20px; /* Adds space at the bottom of the card body */
  }
  

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #007bff;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  /* .custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  background-color: rgba(0, 123, 255, 0.5); 
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.custom-carousel .carousel-control-next-icon {
  filter: none; 
  margin-right: -32px;
}

.custom-carousel .carousel-control-prev-icon{
  margin-left: -32px;
  filter: none; 
}

.custom-carousel .carousel-control-prev:hover,
.custom-carousel .carousel-control-next:hover {
  background-color: rgba(0, 123, 255, 0.7);
} */

