.TransferBetweenAccount_form .form-wrapper{
    max-width: 600px;
}
.TransferBetweenAccount_form .btn-wrapper {
	margin-top: 36px;
	text-align: center;
}
.TransferBetweenAccount_form .btn-wrapper button{
	padding: 15px 40px;
	border-radius: 10px;
}
#TransferBetweenAccount form select option{
	background-color: #ffffff;
}
#TransferBetweenAccount .card{
	padding: 20px;
}