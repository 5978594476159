.store-left {
  text-align: center;
}
.store-left h1{
  font-size: 48px;
}
.store-button {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.store-button button {
  width: 180px;
  border: none;
  background: transparent;
}
.store-button button img{
  object-fit: cover;
  width: 100%;
}
.Trader .card{
  min-width: 200px;
}
.download-card p{
  font-size: 20px;
  color: #000;
}
.download-card img{
  filter: invert(100%);
  width: 60px;
  aspect-ratio: 1/1;
}
