#TransactionHistory .card {
    padding: 20px;
}

#TransactionHistory .card button {
    margin: 0 5px;
}

#TransactionHistory .card form {
    width: 100%;
}

#TransactionHistory .card .form-group {
    width: 45%;
}

#TransactionHistory .card form button {
    height: 38px;
}

#TransactionHistory .is-active {
    background-color: #26a1eb;
    border-radius: 5px;
    border: 1px solid #0090e7;
    color: #FFF;
    font-size: 13px;
    padding: 5px 10px;
}

#TransactionHistory .btn-custom {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #0090e7;
    color: #26a1eb;
    font-size: 13px;
    padding: 5px 10px;
    transition: all .2s ease-out;
}

#TransactionHistory .card .btn-dribbble {
    border-radius: 10px;
    margin: 0;
    padding: .4375rem .75rem;
    height: calc(2.25rem + 2px);
}