#login .main-card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#login .stretch-card {
    max-width: 720px;
    width: 100%;
    background: #1c1c1c4b;
    box-shadow: 0 8px 32px 0 #1f26875e;
    backdrop-filter: blur(10.5px);
    -webkit-backdrop-filter: blur(10.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.092);
    overflow: hidden;
}
.card-illustrator{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#login .head{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
#login .brand-logo{
    width: 150px;
}
#login .login-left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../img/LoginBg.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
#login .card-body{
    width: 60%;
    background-color: #1c1c1cd5;
}
.Illustrator{
    height: 300px;
    padding: 1.5rem;
}
.round{
	width: 40px;
	height: 40px;
	border: 1px solid #e6d8d8;
	border-radius: 55%;
}
.round input{
	border-radius: 50%;
    background: White;
	text-align: center;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
@media screen and (max-width:576px) {
    .card-illustrator{
        display: none;
    }
    #login .stretch-card{
        width: calc(100% - 60px);
    }
    #login .card-body,
    #login .card-illustrator{
        width: 100%;
    }
    #login .card-body{
        background: transparent;
    }
    #login .card-illustrator{
        background-color: #1c1c1c;
    }
}