.is-active {
  background: #fff;
  color: #000 !important;
  border-radius: 0.5rem 0.5rem 0 0;
  /* box-shadow: 0 0 20px 2px #0000001a !important; */
  z-index: 0;
}
.btn {
  font-size: 18px;
}
.is-active:hover {
  background: #fff;
}

.card {
  border-radius: 20px;
  border: none;
}
.card #HomeDemo {
  min-height: 70vh;
  margin: 36px auto;
}
.card .card-body {
  padding: 0;
}

.card .card_item {
  width: 100%;
  padding: 24px 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.card .card_item .item_status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 12px;
  font-size: 14px;
  background-color: #efefef;
  color: #87878b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 0.25rem 0;
}

.item_status_txt {
  font-size: 14px;
  color: #87878b;
}

.status_Active {
  color: #0a9;
}

.card_item .item_top {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  min-height: 30px;
}

.platform_tag {
  margin-right: 10px;
  display: inline-block;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 12px;
  color: #000;
  border-radius: 3px;
  background-color: #d5d5d5;
}

.card_item .item_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 32px;
}

.card_item .item_center .center_l .center_l_data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
}

.card_item .item_center .center_l .center_l_data .center_logo {
  width: 24px;
  border-radius: 50%;
}

.card_item .item_center .center_l .center_l_data .center_num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  padding: 0 6px;
}

.card_item .item_center .center_l .center_l_data .center_num span {
  font-size: 20px;
  font-weight: 700;
}

.card_item .item_center .center_l .center_l_data .center_num .fractional_num {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}

.card_item .item_center .center_l .center_l_data .center_num .center_currency {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  padding-left: 6px;
}

.card_item .item_center .center_r {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card_item .item_center .center_r .el-button {
  min-width: 73px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
}

.el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #00d25b;
  border: 1px solid #a5b5ba80;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  font-weight: 400;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 6px;
}
.m-l-10 {
  margin-left: 10px;
}

.el-button:hover {
  border-color: #dcdfe6;
  background-color: #0f1015;
}

.el-button--medium {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
}

.more_actions_btn .setting_box {
  margin-left: 10px;
  cursor: pointer;
}

.setting_box .setting_icon {
  font-size: 16px;
  color: #000;
}

.card_item .item_bottom {
  color: #6e6e73;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.el-divider--vertical {
  display: inline-block;
  width: 1px;
  height: 1em;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
  background-color: #dcdfe6;
}

@media (max-width: 550px) {
  .content-wrapper {
    padding: 0.75rem;
  }
}
