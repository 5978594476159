#PersonalInformation .is-active-tab {
  border-bottom: 2px solid #000000d3;
}
#PersonalInformation .card .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 7px;
}
.user-information {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
#PersonalInformation .mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.btn.is-active-tab {
  border: 1px solid green; /* Adjust the border width and style as needed */
}

button {
  background-color: transparent;
  border: none;
}

button.active {
  border: 2px solid lightgreen;
  /* outline: none; */
  /* box-shadow: none; */
  background-color: #ffffff;
}

@media (max-width: 999px) {
  button.active {
    border: 2px solid lightgreen;
  }
}

.tab-container {
  width: 100%;
  justify-content: center;
}

@media (max-width: 999px) {
  .tab-container {
    justify-content: unset;
  }
}

.is-active-tab {
  border: 1px solid green;
}

.section-profile-summary .user-information {
  position: relative;
  overflow: hidden;
}

@media (max-width: 999px) {
  .same-width {
    width: 100%;
  }
}

.user-information .bg-circle {
  position: absolute;
  width: 150%;
  height: 100%;
  top: -77%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 0;
  background: #07548d;
  box-shadow: 0 4px 30px 0 rgba(117, 216, 218, 0.2);
}

.user-information .profile-image {
  width: 5.9375rem;
  height: 5.9375rem;
  font-size: 2.125rem;
  background-color: #fff;
  position: relative;
}
.edit-profile {
  display: none;
  background-color: #00000083;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  width: 95px;
  height: 95px;
  justify-content: center;
  align-items: center;
}
.pofile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pofile .profile-image:hover + .edit-profile {
  display: flex !important;
}
#PersonalInformation .date-inputs {
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: space-between;
  position: relative;
}

#PersonalInformation .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.date-inputs input[type="text"] {
  max-width: 30%;
}

@media (min-width: 576px) {
  #PersonalInformation .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

/* #PersonalInformation .form-control:disabled {
	background-color: #fff;
	border: 1px solid #000;
} */

#PersonalInformation .form-row {
  display: flex;
  flex-wrap: wrap;
}

#PersonalInformation .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/*  */
.flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.initial-upload img {
  height: 89px;
}
#PersonalInformation .container-card-doc-upload .document-preview,
#PersonalInformation .container-card-doc-upload .dropzone {
  height: 180px;
}
#PersonalInformation .container-card-doc-upload .document-preview {
  background-size: cover;
  background-color: #f1f3f9;
  border-radius: 8px;
}

.document-preview img {
  width: 100%;
  height: 100%;
}
.file-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 20;
}

.image-icon-upload {
  height: 80px;
}

.or-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 10px;
  z-index: 999;
}

.Qr-code img {
  max-width: 200px;
  max-height: 200px;
  aspect-ratio: 1/1;
}

@media only screen and (min-width: 680px) {
  .container-card-doc-upload {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .or-btn {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
  }
}

#PersonalInformation .round {
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 55%;
}
#PersonalInformation .round input {
  border-radius: 50%;
  text-align: center;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.mobile-font {
  font-size: 14px; /* Adjust the size as needed */
}
/*  */
